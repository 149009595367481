/**
 * Created by Ramor11 on 3/31/2017.
 */
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { TranslateModule } from '@ngx-translate/core';
import { HeaderComponent } from './header.component';
import { FormsModule } from '@angular/forms';
import { ProfileBarModule } from '../profilebar/profilebar.module';
import { OverlayNoRowsTemplateModule } from '../../containers/overlayNoRowsTemplate';
import { BrandComponent } from '../brand/brand.component';

@NgModule({
  declarations: [HeaderComponent],
  imports: [
    CommonModule,
    TranslateModule,
    FontAwesomeModule,
    RouterModule,
    FormsModule,
    ProfileBarModule,
    OverlayNoRowsTemplateModule,
    BrandComponent,
  ],
  exports: [HeaderComponent],
  providers: [],
})
export class HeaderModule {}
