<div
  class="site-name d-flex flex-column align-items-start align-content-center"
>
  <a
    class="blog-header-logo text-dark text-underline text-nowrap"
    [routerLink]="headerRedirectURL"
    queryParamsHandling="preserve"
    >{{ "appName" | translate }}</a
  >
  <sub
    class="sub-heading-font-family d-block text-nowrap"
    [innerHTML]="'ponyLagoonSlogan' | translate"
  ></sub>
</div>
