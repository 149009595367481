<div class="d-flex justify-content-end">
  <div class="dropdown">
    <a
      class="btn btn-light ripple bg-transparent border-0 dropdown-toggle px-2"
      role="button"
      id="profileBarMenuLink"
      data-bs-toggle="dropdown"
      aria-haspopup="true"
      aria-expanded="false"
    >
      <fa-icon [icon]="faUserCircle" size="2x"></fa-icon>
    </a>

    <div
      class="dropdown-menu dropdown-menu-right"
      aria-labelledby="profileBarMenuLink"
    >
      <small
        class="dropdown-item cursor-pointer-text text-center"
        *ngIf="username"
        [innerHTML]="username"
      ></small>
      <a
        class="dropdown-item cursor-pointer text-decoration-none ripple"
        [routerLink]="routerLinkRoutes.DRIVE"
        routerLinkActive="active"
        >{{ "myDrive" | translate }}</a
      >
      <a
        class="dropdown-item cursor-pointer text-decoration-none ripple"
        *ngIf="username"
        [routerLink]="routerLinkRoutes.PROFILE"
        routerLinkActive="active"
        >{{ "profile" | translate }}</a
      >
      <div class="dropdown-divider"></div>
      <a
        class="dropdown-item cursor-pointer ripple"
        (click)="signOut($event)"
        >{{ "signOut" | translate }}</a
      >
    </div>
  </div>
</div>
