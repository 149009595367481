import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { EAppRoutes } from './models';
import { NgModule } from '@angular/core';

export const ROUTES: Routes = [
  {
    path: '',
    loadChildren: () =>
      import('./containers/signin/signin-entry.module').then(
        ({ SigninEntryModule }) => SigninEntryModule,
      ),
  },
  {
    path: EAppRoutes.DASHBOARD,
    loadChildren: () =>
      import('./containers/dashboard/dashboard.module').then(
        ({ DashboardModule }) => DashboardModule,
      ),
  },
  { path: '**', redirectTo: '', pathMatch: 'full' },
];

@NgModule({
  imports: [
    RouterModule.forRoot(ROUTES, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
