<label
  *ngIf="label"
  [innerHTML]="label"
  [class.has-value]="hasName || (_model !== null && _model !== undefined)"
  [class.is-invalid]="ctrl && ctrl.invalid && ctrl.dirty"
  class="form-label label no-pointer-events"
></label>
<select
  class="form-control form-select ace-select text-dark-m1 brc-default-m3 line-height-n radius-0"
  [class.text-dark-m1]="!readonly"
  [class.brc-h-warning-m1]="!readonly"
  [class.bgc-h-warning-l3]="!readonly"
  [class.has-value]="hasName || (_model !== null && _model !== undefined)"
  [(ngModel)]="_model"
  (ngModelChange)="modelChanged($event)"
  [required]="required"
  [disabled]="disabled || readonly"
  [class.is-invalid]="ctrl && ctrl.invalid && ctrl.dirty"
>
  <option *ngFor="let x of selectOptions" [ngValue]="x.value">
    {{ x.name }}
  </option>
  <ng-content></ng-content>
</select>
