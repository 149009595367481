import { Component } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { RouterModule } from '@angular/router';

@Component({
  selector: 'brand-title',
  styleUrls: ['../../containers/public/theme.scss', './brand.component.scss'],
  templateUrl: `./brand.component.html`,
  imports: [RouterModule, TranslateModule],
})
export class BrandComponent {
  public headerRedirectURL = ['/'];
}
