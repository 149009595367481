{
  "appName": "File Manager",
  "registration": "Registration",
  "email": "email",
  "gender": "gender",
  "selectGender": "Select a Gender",
  "selectFiles": "Select a file",
  "fname": "first name",
  "lname": "surname",
  "username": "username",
  "subscribe": "Subscribe",
  "testEmail": "test@ponylagoon.com",
  "testPassword": "t!nyPony2023",
  "redirecting": "Redirecting...",
  "loadNewVersion?": "Load new version?",
  "ponyLagoonSlogan": "An index for files with ease",
  "uploadFiles": "Upload Files",
  "uploadFile": "Upload File",
  "uploadImage": "Upload Image",
  "storage": "Storage",
  "upgrade": "Upgrade",
  "welcomeBack": "Welcome back",
  "upload": "Upload",
  "grantAccess": "Available to",
  "accountTypes": "Account Types",
  "confirmEmail": "Confirm Email Address",
  "specialOffer": "Special Offer",
  "selectedAccount": "Current Plan",
  "account": "Account",
  "directoryPathUpload": "Directory path where the new uploaded files will be added.",
  "selectFileToUpload": "Select file to upload to directory:",
  "newVersionAvailable": "New Version Available",
  "usernameInfoDetails": "Your username is to identify your personal page. ex: u/{{username}}",
  "attachImagesUploaderHeader": "Attach Photos",
  "privateUploaderHeader": "Private Photos",
  "dragImagePrivate": "Drag the images you want to make private",
  "rowNumber": "Row Number {{number}}",
  "attachFiles": "Attach Files",
  "attachHyperlinks": "Attach Hyperlinks",
  "attachImages": "Attach Images",
  "settingsPrivacy": "Settings & Privacy",
  "settingsPrivacyNote": "Change user's site preferences and contact.",
  "membership": "Membership & Billing",
  "membershipChanges": "Membership and Billing Changes",
  "share": "Share",
  "COPIED": "Copied",
  "COPY": "Copy",
  "models": "Models",
  "PRIVATE": "No one can view, Private",
  "PUBLIC": "Everyone can view",
  "expiresAfter": "Access expires after",
  "RESTRICTED": "Some people with access",
  "LINK_ACCESS": "Anyone with the link",
  "peopleWithAccess": "People with access",
  "peopleWithAccessNote": "Email addresses for the people to share this with, They'll need to signing before they can access the item.",
  "personalInformation": "Personal Information",
  "personalInformationNote": "Update user's contact email, name, username",
  "updatePersonalInformation": "Update Personal Information",
  "accountSettings": "Account Settings",
  "privacySettings": "Privacy Settings",
  "attachAwards": "Attach Awards",
  "buyStorage": "",
  "shareURL": "Share URL:",
  "showPass": "Show Password",
  "movePrivate": "Move Private",
  "movePublic": "Move Public",
  "expiresOn": "Expires on: {{date}}",
  "aboutMe": "About Me",
  "description": "description",
  "appEntrySummary":"Manage your files<br />Tool to help you organize your files, uploaded from simple checkbook.",
  "privacySettingsOpts": {
    "followNotify": {
      "false": {
        "value": "off",
        "description": "no email notifications"
      },
      "true": {
        "value": "on",
        "description": "receive Notification from people you follow"
      }
    },
    "likesNotify": {
      "null": {
        "value": "off",
        "description": "no email notifications"
      },
      "true": {
        "value": "on",
        "description": "Public"
      },
      "false": {
        "value": "off",
        "description": "Friends Only"
      }
    },
    "privacyStates": {
      "null": {
        "value": "private",
        "description": "Private"
      },
      "true": {
        "value": "public",
        "description": "Public"
      },
      "false": {
        "value": "friendsOnly",
        "description": "Friends Only"
      }
    },
    "settingsPrivacyOpts": {
      "opt1": {
        "description": "Who can see your first & last name?",
        "key": [
          "fname",
          "lname"
        ]
      },
      "opt2": {
        "description": "Who can contact you?",
        "key": [
          "contactme"
        ]
      },
      "opt3": {
        "description": "Who can see your friends list?",
        "key": [
          "friends"
        ]
      }
    }
  },
  "updateAward": "Update Award",
  "editAward": "Edit Award",
  "deleteImage": "Delete Image",
  "invalidImage": "Image contains unsupported image format.",
  "deleteImageConfirmation": "This image is to be deleted. Any models which this image is linked, will be removed.<br />\nThis cannot be undone.",
  "unlinkImage": "Unlink Image",
  "makePrimary": "Set Primary",
  "shortDescription": "short description",
  "subtitle": "subtitle",
  "listingFormat": "listing format",
  "itemDetails": "item details",
  "details": "details",
  "profileImage": "profile image",
  "setProfile": "Set Profile",
  "detailCollection": "Include details to help users find your collection.",
  "makePublic": "Set Public",
  "clickOnImagesToSelect": "select images",
  "main": "main",
  "images": "Images",
  "other": "other",
  "photos": "photos",
  "private": "private",
  "profile": "Profile",
  "trash": "Trash",
  "monthly": "Monthly",
  "update": "update",
  "usernameExist": "Username already exists.",
  "emailExist": "Email already exists.",
  "yearly": "Annual",
  "myGallery": "My Gallery",
  "learnMore": "Learn More",
  "password": "password",
  "newPassword": "new password",
  "enterNewPassword": "Enter your new password",
  "secureLogin": "Secure login",
  "emailConfirm": "Confirm Email",
  "currentPassword": "current password",
  "updateSuccessful": "Update Successful",
  "uploadedSuccessful": "Uploaded Successful",
  "forgotPasswordBtn": "Password Reset",
  "forgotPassword": "Please check your email address to reset password.",
  "registerSuccess": "We're happy you signed up for Simple Checkbook. To start please confirm your email address.",
  "imagesPrivacy": "Images are private by default, click on the image menu to change the privacy settings.",
  "imagesCopy": "Images may be subject to copyright.",
  "columnValue": "Column {{letter}}",
  "columns": "Columns",
  "pivotColumns": "Pivot Columns",
  "columnLetter": "Column {{letter}}",
  "filters": "Filters",
  "buy": "Buy",
  "edit": "Edit",
  "on": "On",
  "off": "Off",
  "everyone": "Everyone",
  "followersOnly": "Followers only",
  "pushNotifications": "Email Notifications",
  "loadMoreImages": "Load more items",
  "EXCEED_SPACE_LIMIT": "Exceeded account storage limit.",
  "click2upload": "Click to upload image",
  "buyBtn": "Buy Button",
  "emailBtn": "Email Button",
  "venueDate": "Event Date",
  "venueName": "Venue/Show Name",
  "showMoreResults": "Show More Results",
  "tools": "Tools",
  "customize": "Customize",
  "search": "Search",
  "token": "token",
  "submit": "Submit",
  "follow": "Follow",
  "followRequest": "Follow Request Sent",
  "unfollowMessage": "<span class=\"text-danger\">Unfollow</span> u/{{username}}?",
  "unfollow": "Unfollow",
  "following": "Following",
  "followers": "Followers",
  "saveChanges": "Save Changes",
  "accountPublicAlert": "everyone can see your account",
  "accountPrivateAlert": "no one can see your account",
  "accountPrivateWarning": "Your account is {{status}}, {{alertMessage}}. Your profile <mark>{{appRouteProfile}}</mark> and your individual model pages are {{status}}. To make changes to your account, edit your account in Settings & Privacy.",
  "demoAlertUsername": "You are currently log in as <mark>u/{{username}}</mark>. To view your dashboard route to <mark>/m/drive</mark>.",
  "contactMe": "Contact Me",
  "removeAward": "Remove Award",
  "renameFile": "Rename file",
  "storageInformation": "{{format_used_storage}} of {{format_total_storage}}",
  "match": "Match",
  "requiredToken": "Invitation Token Required",
  "requiredTokenMuted": "Site is currently only accepting registration by invitation only. Please contact the administrator for an invitation. ",
  "emailAddressRequired": "Email Address Required",
  "emailAddressRequiredMuted": "Email Address will be used to verify account access, once you have submitted check your email for a registration link. ",
  "tokenWait": "Please check your email inbox for link authorization to PonyLagoon",
  "filesAddedToMedia": "All images uploaded and linked to an item, is available here. Any images that you upload in <span class=\"text-underline\">My Gallery</span>, can also be access in a folder added to your main dashboard.",
  "contains": "Contains",
  "removeLinkAddress": "Remove Link Address",
  "addLinkAddress": "Add Link Address",
  "addEntry": "Add Entry",
  "public": "public",
  "addRecord": "Add Record",
  "entryDetails": "Entry Details",
  "updateRecord": "Update Record",
  "rowPublic": "This item is public.",
  "addDirectory": "Add New Directory",
  "accountIsStatus": "Account is {{status}}",
  "addSheet": "Add New Sheet",
  "overrideFileName": "Do you wish to override existing file<br /><code>{{name}}</code>?<br />\nThis cannot be undone.  The file will be irretrievably changed if they are not also stored elsewhere.\n",
  "deleteModel": "Are you sure you want to delete this item?<br />\nThis cannot be undone.",
  "sheetName": "Sheet Name",
  "untitledSheet": "untitled sheet",
  "untitled": "untitled",
  "directory": "directory",
  "billingAddress": "Billing Address",
  "selectPlan": "Select your plan",
  "dropImage": "Drop/Upload Image",
  "addImageLink": "Add Image Link",
  "male": "Male",
  "female": "Female",
  "toolBox": "Tool Box",
  "properties": "Properties",
  "termsCondition": "I agree with the <a href=\"/privacy-policy\" target=\"_blank\"> Terms and Conditions </a>",
  "haveAccount": "Already have an account?",
  "dontHaveAccount": "Not a member?",
  "signIn": "Sign In",
  "signOut": "Sign Out",
  "register": "Register",
  "thanksRegister": "Thank you for your registration!",
  "passwordConfirm": "Confirm Password",
  "forgotPass": "Forgot password?",
  "passwordReset": "Password Reset",
  "resetPassword": "Reset Password",
  "membershipBilling": "Membership and Billing Changes",
  "checkEmail": "Please, check your email.",
  "remove": "Remove",
  "apply": "Apply",
  "registerAccountRequired": "Must have a Registered Account.",
  "premiumAccountRequired": "Must have a Premium Account.",
  "proAccountRequired": "Must have a Professional Account.",
  "noRowsSelected": "No Rows Selected",
  "notAvailableGrid": "Not Available in Grid View",
  "size": "size",
  "status": "status",
  "preview": "Preview",
  "name": "name",
  "demo": "Demo",
  "privacyTerms": "Privacy & Terms",
  "sale": "sale",
  "cvv": "CVV",
  "paymentReview": "Review",
  "ccNumber": "Credit Card Number",
  "expirationNumber": "Expiration date",
  "subscriptionDetails": "Subscription Details",
  "paymentDetails": "Payment Details",
  "billingZipCode": "Billing zipcode",
  "subscription": "Subscription",
  "subscriptionNoteOrg": "This subscription will be renewed automatically (Credit Card or PayPal only).",
  "subscriptionNote": "This subscription will be renewed automatically.",
  "subscriptionTerms": "By providing your payment information, you allow PonyLagoon to charge your account for future payments in accordance with our terms.",
  "send": "Send",
  "pricing": "Pricing",
  "message": "message",
  "subject": "subject",
  "contactUs": "contact us",
  "forSale": "For sale badge",
  "sendEmail": "Send Email",
  "sendMessage": "Send Message",
  "messageSend": "Message Sent",
  "professional": "Professional",
  "standard": "Standard",
  "premium": "Premium",
  "getStarted": "Get Started",
  "about": "About",
  "enterDate": "Enter Date",
  "venuesAwards": "Shows/Awards",
  "addVenueAward": "Add Show/Award",
  "editVenueAward": "Edit Show/Award",
  "gallery": "Gallery",
  "myDrive": "My Drive",
  "contentType": "content type",
  "modelName": "Model Name",
  "streetAddress": "Street address",
  "city": "City",
  "State": "State",
  "postalCode": "Postal code / ZIP",
  "country": "Country / region",
  "modelGender": "Model Gender",
  "classNum": "Class #",
  "numEntries": "# Entries",
  "judge": "Judge",
  "place": "Place",
  "champion": "Champion",
  "reserve": "Reserve",
  "gChampion": "Grand Champion",
  "gReserve": "Grand Champion Reserve",
  "NaN": "NaN Card",
  "className": "Class Name",
  "modelTitle": "Model Title",
  "previousModel": "Previous Model",
  "nextModel": "Next Model",
  "lastUpdate": "last update",
  "features": "Features",
  "friends": "friends",
  "addAward": "Add Award",
  "youGotMail": "You got {{number}} notifications",
  "maxLimitReachedUpgrade": "Max Limit Reached",
  "overlayLoadingTemplate": "Loading...",
  "overlayNoRowsTemplate": "No Rows To Show",
  "noCellSelected": "No cell is selected.",
  "addImage": "Add image",
  "noImagesFound": "<span class=\"font-weight-bold\">No Images</span>&nbsp;found",
  "filesOverlayNoRowsTemplate": "This folder is empty",
  "filesOverlayNoRowsTemplateDrive": "No items found",
  "itemsInTrash": "Items moved to the trash will appear here",
  "incorrectFileFormat": "Incorrect File type",
  "emailChangeWarning": "  By changing your email, your account will be logged out. You will receive an email from PonyLagoon to activate changes.",
  "INVALID_EMAIL": "Failed to authenticate account.",
  "ACCOUNT_ALREADY_EXISTS": "Account already exist.",
  "enterPassChanges": "You must enter your current password to make any changes to your account.",
  "FAILED_AUTHENTICATE": "Failed to authenticate.",
  "FAILED_AUTHENTICATE_TOKEN[authenticated_client]": "Failed to authenticate account. Please sign in.",
  "awardsOverlayNoItemsTemplateDrive": "No records found",
  "updatePrivacySettings": "Update Privacy Settings",
  "updateAccountSettings": "Update Account Settings",
  "sorryNothingHere": "Sorry Nothing Here",
  "cancelSubscription": "Cancel Subscription",
  "cancelSubscriptionConfirmation": "Do you confirm to cancel subscription?",
  "updateMembership": "Update Membership",
  "accountUpdated": "Account Updated",
  "rowUpdate": "Row Updated",
  "changesSaved": "Changes are saved",
  "toaster": {
    "success": "Success",
    "info": "Information",
    "warning": "Warning",
    "error": "Error",
    "defaultError": "Something went wrong."
  },
  "settingsPrivacyOpts": {
    "opt0": "Who can see your profile picture?",
    "opt1": "Who can see your username?",
    "opt2": "Who can see your email address?",
    "opt3": "Who can see your first & last name?",
    "opt4": "Who can contact you?",
    "opt5": "Who can see your tags?",
    "opt6": "Who can see your friends list?"
  },
  "download": "download",
  "showHideColumns": "Show/Hide Columns",
  "showColumns": "Show Columns",
  "hideColumns": "Hide Columns",
  "menu": {
    "move2Trash": "Move to trash",
    "permanentDelete": "Permanent Delete",
    "publishWeb": "Publish on the web",
    "home": "Home",
    "move": "Move",
    "file": "File",
    "view": "View",
    "model": "Model",
    "aboutUs": "About Us",
    "download": "Download",
    "freeze": "Freeze",
    "insert": "Insert",
    "rows": "Selected Rows",
    "addImage": "Add Image",
    "freezeRowsColumns": "Freeze rows and columns",
    "freezeCells": "Freeze cells",
    "freezeFirstRow": "Freeze first row",
    "freezeFirstColumn": "Freeze first column",
    "deleteSelectedRows": "Delete selected rows",
    "insertColumn": "Insert new column",
    "insertRow": "Add new Pony",
    "columns": "Selected Columns",
    "sizeToFit": "Columns size to fit",
    "autoSizeColumns": "Auto-size all columns",
    "autoSizeColumnsSkipHeaders": "Auto-size all columns (skip-headers)"
  },
  "cell": {
    "index": "Cell Index",
    "addImage": "Add image",
    "removeImage": "Remove image",
    "addHyperlink": "Insert hyperlink",
    "removeHyperlink": "Remove hyperlink",
    "editHyperlink": "Edit hyperlink",
    "select": "Select Cell",
    "imageColumn": "Image Column",
    "selectColumn": "Select Column",
    "selectIndex": "Select Cell[index]",
    "value": "Cell Value",
    "cardTitle": "Card Title",
    "addColumn": "Add Column Text"
  },
  "unpublished": "Unpublished",
  "publish": "Publish",
  "fileRemoved": "File removed",
  "includeGalleryUpload": [
    "Click here",
    "to select images from your gallery"
  ],
  "dragDropOrClickGallery": [
    "Drag drop image or",
    "click",
    "to upload image."
  ],
  "UPLOAD_COMPLETED": "Upload Completed",
  "UPLOAD_FAILED": "Upload Failed",
  "READING": "Reading XLS",
  "READING_COMPLETED": "Completed Reading XLS",
  "shareUrl": "Share URL",
  "yesIAccept": "Yes, I accept",
  "recycleBin": "Recycle Bin",
  "yes": "Yes",
  "no": "No",
  "filename": "File name",
  "filetype": "File type",
  "filesize": "File size",
  "sheetColumnCount": "# of Columns",
  "sheetRowCount": "# of Rows",
  "fileImageCount": "Total # Images",
  "filesCount": "Total Files",
  "permanentlyDelete": "Permanently Delete",
  "imagesLinkToRowAlert": "Free accounts are only allowed {{number}} images per item. This item has a total of {{totalImages}} images left",
  "autoDeleteMessage": "Trash that is more than 30 days will be automatically deleted.",
  "setTobeDeleted": "<code>{{filename}}</code> is to be deleted. Files that is more than 30 days will be automatically deleted.",
  "autoDeleteNow": "Delete all trash now.",
  "emailAddress": "Email address",
  "starred": "Favorites",
  "gridView": "Grid View",
  "listView": "List View",
  "dimensions": "Dimensions",
  "endOfContent": "End of Content",
  "noMorePagesLoad": "No more items to load",
  "width": "Width",
  "readmore": "Read More...",
  "height": "Height",
  "location": "Location",
  "exportSheets": "Export sheets",
  "exportColumns": "Export columns",
  "allColumns": "All columns",
  "visibleColumns": "Visible columns",
  "allSheets": "All sheets",
  "copyUrl": "Copy url",
  "offlineSupport": "Use Offline",
  "lineNumber": "<span  class=\"text-monospace\">Line #:</span> {{number}}",
  "contextMenu": {
    "properties": "Properties",
    "open": "Open",
    "share": "Share",
    "linkImage": "Link images",
    "getPublicLink": "Get public link",
    "moveTo": "Move to",
    "addToStarred": "Add to Favorites",
    "removeFromStarred": "Remove from Favorites",
    "rename": "Rename",
    "renameSheet": "Rename sheet",
    "viewDetails": "View details",
    "download": "Download",
    "downloadRow": "Download row",
    "downloadRows": "Download selected rows",
    "deleteRow": "Delete row",
    "clearRow": "Clear row",
    "clearRows": "Clear selected rows",
    "restore": "Restore",
    "makeACopy": "Make a copy",
    "pinRow": "Freeze Row",
    "emptyRecycleBin": "Empty Recycle Bin"
  },
  "disclaimer": {
    "title": "Welcome to {{siteName}}",
    "body": ""
  },
  "support": "Support",
  "displayName": "File name",
  "displayNameNote": "The name of the file, with the required extension.",
  "whatURLshouldLinkto": "To what URL should this link go?",
  "worksheet": {
    "name": "Worksheet Name",
    "sheet": "Worksheet: {{name}}"
  }
}
